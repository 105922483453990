import styled from "@emotion/styled";

export const StyledContainer = styled.div`
  .emotion-InputWrapper-label {
    font-size: 14px;
    font-weight: 500;
    color: #282a3a;
  }

  .emotion-Input-input {
    font-size: 14px;
    font-weight: 400;
  }
`;
