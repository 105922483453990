import styled from "@emotion/styled";

export const StyledWrapper = styled.div`
  .limit-wrapper {
    background: #f0f2f5;

    input {
      margin-bottom: 0;
      border: 1px solid #d2d4dacc;
      border-radius: 8px;
      font-size: 14px;
    }
  }
`;
