import styled from "@emotion/styled";

import Button from "metabase/core/components/Button";

export const AddAggregationButtonRoot = styled(Button)`
  padding: 8px;
  background-color: var(--mb-color-bg-light);

  &:hover {
    background-color: var(--mb-color-bg-light);
  }
`;
