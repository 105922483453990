import styled from "@emotion/styled";

export const FormDataWrapper = styled.div`
  label {
    font-size: 14px;
    font-weight: 500;
    color: #282a3a;
  }

  input,
  textarea,
  button {
    font-weight: 400;
    font-size: 14px;
  }
`;
