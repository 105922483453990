import styled from "@emotion/styled";

export const ColumnGroupName = styled.div`
  margin: 1rem 0 0.5rem 0;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.75rem;
  padding: 0 0.5rem;
  color: #101223;
`;

export const SearchContainer = styled.div`
  margin-bottom: 1rem;

  input {
    border: 1px solid #d2d4da;
    border-radius: 30px;
    font-weight: 400;
    font-size: 12px;
    color: #9496a1;
  }

  input:focus {
    outline: none;
  }
`;

export const ListStyledWrapper = styled.div`
  background-color: #fff;
  border-radius: 16px;
  margin-top: 5px;
  padding: 0 10px;
`;
