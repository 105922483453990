import styled from "@emotion/styled";

export const Text = styled.button`
  color: #ff1a26;
  font-weight: 500;
  text-decoration-line: underline;
  cursor: pointer;
`;

export const StyledCon = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: #333;
`;
