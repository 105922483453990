import styled from "@emotion/styled";

export const StyledWrapper = styled.div`
  .custom-column-wrapper {
    background: #f0f2f5;
  }

  .custom-column.dashedBorder {
    border-color: #000;

    > div {
      background: #fff;
    }

    .icon_label {
      color: #000;
    }
  }
`;
